import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: {name: 'home'},
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/login2',
        name: 'login2',
        component: () => import('../views/Login2.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/Detail.vue')
    },
    {
        path: '/mine',
        name: 'mine',
        component: () => import('../views/Mine.vue')
    },
    {
        path: '/history',
        name: 'history',
        component: () => import('../views/History.vue')
    },
    {
        path: '/manager',
        name: 'manager',
        component: () => import('../views/manager/Manager.vue')
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../views/manager/Users.vue')
    },
    {
        path: '/anchor',
        name: 'anchor',
        component: () => import('../views/Anchor.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
